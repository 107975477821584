<template>
  <div>
    <!-- Top Navigation -->
    <div class="ml-4 pl-3">
      <route-back-button />
    </div>
    <!-- Top Navigation Finished-->
  </div>
</template>

<script>
export default {
  name: "ShowInsuranceCoordinator"
}
</script>

<style scoped>

</style>
<template>
  <div>
    <!-- Top Navigation -->
    <div class="ml-4 pl-3">
      <route-back-button />
    </div>
    <!-- Top Navigation Finished-->
    <div class="m-0 pl-3 pt-4 pb-0 pr-2">
      <router-link :to="{name: 'edit-insurance-coordinator', params: {id: api_param.insuranceCoordinatorId}}">
      <base-button size="lg" class="float-right mr-4 d-inline-block black-outline-button" type="secondary">
        Edit
      </base-button>
      </router-link>
    </div>
    <div class="container-fluid mt-6">
      <div class="row">
        <div class="col">
          <card class="no-border-card equal-height-columns" body-classes="px-0 pb-1" footer-classes="pb-2"
                id="basic-info-card" v-loading="loaders.card">
            <div slot="header" class="position-relative">
              <h1 style="min-height: 40px " class="mt-4 ml-2 patient-name">
                {{ insuranceCoordinator.name }}
              </h1>
              <div class="d-flex flex-row">
                <p class="ml-2 patient-status pl-3 pr-3 pt-1 pb-1"
                   :class="insuranceCoordinator.status === 0 ? 'inactive' : 'active'">
                  {{ getStatus }}
                </p>
              </div>
            </div>

            <h2 style="border-top: 1px solid rgba(0, 0, 0, 0.05)" class="mt-3 pl-4 pt-4 patient-detail-heading">Content:</h2>

            <div class="row pt-2 pl-4">
              <div v-if="insuranceCoordinator !== []" class="col-6">
                <p class="black_p" v-html="insuranceCoordinator.content"></p>
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import BackButton from "@/components/Router/BackButton";

export default {
  components: {
    RouteBackButton: BackButton,
  },
  data() {
    return {
      loaders: {
      },
      api_param: {
        insuranceCoordinatorId: this.$route.params.id,
      },
      insuranceCoordinator: {
        name: '',
        content: '',
      },
    };
  },
  created() {
    this.getInsuranceCoordinator();
  },
  methods: {
    getInsuranceCoordinator() {
      let vm = this;
      vm.loaders.card = true;
      axios.get(this.$store.getters.getBaseUrl + '/api/insurance-coordinators/' + this.api_param.insuranceCoordinatorId).then((response) => {
        vm.$store.commit('toggleSessionCheck', true)
        vm.$store.commit('updateSessionTime', 0)
        vm.insuranceCoordinator = response.data.data;
        vm.loaders.card = false;
      }).catch(error => {
        console.log(error.response)
      }).finally(() => {
        vm.loaders.card = false;
      });
    },
  },
  computed: {
    getStatus() {
      return this.insuranceCoordinator.status === 0 ? 'Inactive' : 'Active';
    },
  }
};
</script>

<style scoped>
.patient-details-container {
  padding-right: 0 !important;
}

#edit-user-details-button {
  top: -20px;
  right: 0;
  padding-left: 37px;
  padding-right: 37px;
}

.patient-name {
  text-align: left;
  font: normal normal 600 25px/34px Open Sans;
  letter-spacing: 0;
  color: #32325D;
  opacity: 1;
}

#patient-email {
  text-align: left;
  font: normal normal 600 17px/23px Open Sans;
  letter-spacing: 0;
  color: #8898AA;
  opacity: 1;
}

.patient-status {
  box-shadow: 0px 3px 2px #E9ECEF0D;
  border-radius: 20px;
  opacity: 1;
  text-align: left;
  font: normal normal 600 12px/17px Open Sans;
  letter-spacing: 0.48px;
  color: #FFFFFF;
}

.active {
  background: #94B527 0 0 no-repeat padding-box;
}

.inactive {
  background: #BB2255 0 0 no-repeat padding-box;
}

.patient-detail-heading {
  text-align: left;
  font: normal normal bold 16px/22px Open Sans;
  letter-spacing: 0;
  color: #172B4D;
  text-transform: uppercase;
  opacity: 1;
}

.patient-detail-text {
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0;
  color: #8898AA;
  opacity: 1;
}

.internal-notes-container, .patient-detail-messages {
  padding-left: 30px;
  padding-right: 30px;
}

.custom-button {
  padding-top: 8px;
  padding-bottom: 8px;
}
</style>

<style>
.no-border-card .card-footer {
  border-top: 0;
}

#basic-info-card .card-body {
  padding-top: 0 !important;
}

#basic-info-card .card-header {
  padding-bottom: 0 !important;
}

.card-header {
  border: none;
}

</style>
